<template>
  <b-card no-body>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
    >
      <b-card-title
        tag="h1"
        class="m-0 py-1 px-2"
      >
        <b-row
          align-h="end"
          align-v="center"
        >
          <b-col cols="auto">
            <b-link
              class="text-indigo px-50"
              @click="handlePrintOrderLinkClick"
            >
              <feather-icon
                v-b-tooltip.hover 
                title="Descargar"
                icon="PrinterIcon"
                size="24"
              />
            </b-link>
            <b-link
              v-access="{
                resource: $data.$constants.RESOURCES.RESOURCE_ORDERS,
                resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
              }"
              :to="{ name: 'editOrder', params: { id: $route.params.id} }"
              class="d-inline-block px-50 text-indigo"
            >
              <feather-icon
                v-b-tooltip.hover 
                title="Editar"
                icon="Edit2Icon"
                size="18"
              />
            </b-link>
            <b-link
              v-access="{
                resource: $data.$constants.RESOURCES.RESOURCE_ORDERS,
                resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
              }"
            >
              <span
                class="d-inline-block px-50 text-danger"
                @click="handleDeleteOrderButtonClick()"
              >
                <feather-icon
                  v-b-tooltip.hover 
                  title="Eliminar"
                  icon="TrashIcon"
                  size="18"
                />
              </span>
            </b-link>
          </b-col>
        </b-row>
        <b-row
          align-v="center"
          align-h="between"
        >
          <b-col cols="auto">
            <small>Pedido Nº{{ order ? order.number : '' }}</small>
            <h2 class="font-weight-bold text-capitalize text-dark mb-0">
              {{ order && order.provider ? order.provider.name : '' }}
              <span class="font-medium-1">
                <StatusBadge
                  class="mx-1"
                  :status="orderStatus"
                />
                <StatusBadge :status="deliveryNotesStatus" />
              </span>
            </h2>
            <small class="font-weight-bold">
              {{ orderDateText }}
            </small>
          </b-col>
        </b-row>
      </b-card-title>
      <hr class="m-0">
      <ProfilePanel :profile="orderProfile" />
    </b-overlay>
  </b-card>
</template>

<script>
import ProfilePanel from '@/components/ui/panel/ProfilePanel.vue'
import StatusBadge from '@/components/status/badge/StatusBadge.vue'
import { mapActions, mapGetters } from 'vuex'
import OrdersApi from '@/api/orders-api'

export default {
  name: 'OrderProfileCard',
  components: { StatusBadge, ProfilePanel },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('orders', {
      order: 'getOrder',
    }),
    orderProfile() {
      if (!this.order) {
        return []
      }

      return [
        { label: 'División', value: this.order?.division?.name || 'Sin división' },
        { label: 'Categoría', value: this.order?.category_group?.name || 'Sin categoría' },
        { 
          label: 'Job', 
          value: this.order?.job?.name || 'Sin job',
          link: !!this.order?.job, 
          id: this.order?.job?.id, 
          routeName: 'viewJob' 
        },
        { label: 'Forma de pago', value: this.order?.type_payment?.name || 'Sin forma de pago' },
      ]
    },
    orderDateText() {
      if (!this.order?.order_date) {
        return 'Sin fecha de pedido'
      }

      return new Date(this.order.order_date).toLocaleDateString()
    },
    orderStatus() {
      return this.order?.status?.name || ''
    },
    deliveryNotesStatus() {
      return this.order?.status_notes?.name || ''
    },
  },
  methods: {
    ...mapActions('app', ['setLoading']),
    async handlePrintOrderLinkClick() {
      if (!this.order) {
        return
      }

      this.loading = true
      try {
        await OrdersApi.printOrder(this.order.id)
      } finally {
        this.loading = false
      }
    },
    async handleDeleteOrderButtonClick() {
      if (!this.order) {
        return
      }

      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro Nº${this.order.number}.`,
      })

      if (!response.isConfirmed) {
        return
      }

      this.setLoading(true)
      try {
        await OrdersApi.delete(this.order.id)
        await this.$router.push({ name: 'orders' })
      } finally {
        this.setLoading(false)
      }
    },
  },
}
</script>

<style scoped>
</style>
